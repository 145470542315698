<template>
  <div class="auth__form__content" id="auth-login">
    <form v-on:submit="login">
      <h2 class="is-size-2">Log In into your account</h2>
      <div class="is-size-4">Log In to access roketin apps</div>
      <br />
      <b-field label="Email">
        <b-input
          v-model="email"
          placeholder="e.g: admin@roketin.com"
          size="is-size-5"
          required
        ></b-input>
      </b-field>
      <b-field label="Password">
        <b-input
          v-model="password"
          type="password"
          size="is-size-5"
          placeholder="password"
          required
          password-reveal
        ></b-input>
      </b-field>
      <b-field class="has-text-right">
        <!-- <b-checkbox>Remember Me</b-checkbox> -->
        <router-link
          to="forgot-password"
          @mouseover="hover = true"
          class="is-link is-size-5 has-text-weight-semi-bold"
        >
          Forgot Password
        </router-link>
      </b-field>
      <b-field class="is-flex is-justify-content-center">
        <b-button native-type="submit" class="is-size-5 mb-2" expanded>
          Login
        </b-button>
      </b-field>
    </form>
    <div
      class="auth__form__footer is-flex"
      v-if="getVueAppUseRegister == 'yes'"
    >
      <div class="is-size-5">
        {{ `Don't have an account yet? ` }}
        <span>
          <router-link
            to="registration"
            class="is-link is-size-5 has-text-weight-semi-bold"
          >
            Register
          </router-link>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { showToast } from '@/services/util'
export default {
  data() {
    return {
      email: null,
      password: null,
    }
  },
  computed: {
    getVueAppUseRegister() {
      return process.env.VUE_APP_USE_REGISTER
    },
  },
  methods: {
    login: function (e) {
      e.preventDefault()
      let email = this.email
      let password = this.password
      let redirect = '/select/company'
      let serviceId = this.$route.query.service_id
      if (serviceId !== undefined) {
        redirect = '/select/company?service_id=' + serviceId
      }
      this.$store
        .dispatch('auth/login', { email, password, redirect })
        .then(() => {
          console.log()
        })
        .catch((error) => {
          console.log(error)
          showToast('Login Failed', 'is-danger', 'is-bottom')
        })
    },
  },
}
</script>
